import React, { useContext, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PopperContext } from 'components/StoryblokPopup'
import StoryblokComponents from 'components/StoryblokComponents'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMore from '@material-ui/icons/ExpandMore'

const Panel = styled(ExpansionPanel)`
  && {
    && {
      &:before {
        display: none;
      }
      box-shadow: none;
      border: none;
    }
  }
`

const Summary = styled(ExpansionPanelSummary)`
  && {
    padding: 0 48px;
  }
  .expandIconLeft {
    position: absolute;
    left: 0;
    right: auto;
  }
`

const Details = styled(ExpansionPanelDetails)`
  > * {
    width: 100%;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default function StoryblokExpansionPanel({ data }) {
  const { popper, panelsOpen, setPanelOpen } = useContext(PopperContext)
  const onChange = useCallback(
    (e, bool) => {
      setTimeout(() => popper.current?.scheduleUpdate(), 150)
      setPanelOpen({ [data.id]: bool })
    },
    [popper]
  )

  return (
    <Panel
      onChange={onChange}
      expanded={!!panelsOpen[data.id]}
      TransitionProps={{ timeout: popper ? 300 : 0 }}
    >
      <Summary
        classes={{ expandIcon: 'expandIconLeft' }}
        expandIcon={<ExpandMore />}
      >
        <StoryblokComponents components={data.summary} />
      </Summary>
      <Details>
        <StoryblokComponents components={data.details} />
      </Details>
    </Panel>
  )
}
